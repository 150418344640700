@import './styles/var';
@import './styles/mixins';
// @import '~video-react/styles/scss/video-react';
.comment_data{
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    @include media-breakpoint-down-sm{
        margin-left: 0 !important;
    }
    flex: 1;
    input{
        outline: none !important;
        border: none !important;
        flex: 1;
        &::placeholder{
            font-size: 14px !important;
            line-height: 16px !important;
            color: #D1D1D1 !important;
        }
    }
  }
  
.comment_page{
    .title{
        margin-bottom: 31px;
        @include media-breakpoint-down-sm{
            font-size: $fz-12;
            margin-bottom: 15px;
            margin-top: -15px
        }
    }
    .text_name{
        font-weight: 500;
        // float: left
    }
    .work_slider{
        @include size(100%, 700px);
        @include imageSize(cover, center);
        @include media-breakpoint-down-md{
          //  height: 470px;
        }
        @include media-breakpoint-down-sm{
            // height: 300px;
            margin:8px 0px ;
        }
        //border-radius: 10px;
        margin:0px 0px ;
    }
    .see_more{
        font-weight: $regular;
        font-size: $fz-12;
        line-height: 14px;
        text-decoration-line: underline;
        color: #747474;
        margin-top: 27px;
        margin-bottom: 21px;
        cursor: pointer;
    }
   
    
}

  